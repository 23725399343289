var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      key: `${_vm.$route.query.highlightId}`,
      attrs: { id: "SessionDetail", fluid: "", tag: "section" },
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  loading: _vm.detailLoading,
                  item: _vm.detail,
                  cols: _vm.sessionInfoRows,
                  title: "Session Info",
                  color: "secondary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.start_time.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Date")(item.start_time)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.end_time.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Date")(item.end_time)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.session_mongo_id.value`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              staticClass: "elevation-0 transparent",
                              attrs: {
                                icon: "",
                                "x-small": "",
                                color: "kajot-text",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { size: "15" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.copySession(
                                        item.session_mongo_id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" mdi-content-copy ")]
                              ),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item.session_mongo_id))]),
                        ]
                      },
                    },
                    {
                      key: `item.player_mongo_id.value`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: `player-detail`,
                                  params: {
                                    player_id: item.player_mongo_id,
                                    breadcrumbs: {
                                      title: `Player ${item.player_mongo_id}`,
                                    },
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.player_mongo_id) +
                                  " " +
                                  _vm._s(
                                    item.player ? `/ ${item.player}` : ``
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.start_balance.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.start_balance, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.device.value`,
                      fn: function ({ item }) {
                        return [
                          item.device === 0
                            ? _c("v-icon", [
                                _vm._v(" mdi-projector-screen-outline "),
                              ])
                            : item.device === 1
                            ? _c("v-icon", [_vm._v("mdi-monitor")])
                            : item.device === 2
                            ? _c("v-icon", [_vm._v("mdi-cellphone")])
                            : item.device === 3
                            ? _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v(" mdi-tablet "),
                              ])
                            : _c("span", [_vm._v("unknown")]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  loading: _vm.detailLoading,
                  item: _vm.detail,
                  cols: _vm.sessionBalanceRows,
                  title: "Session Balance",
                  color: "secondary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.sum_bet.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.sum_bet, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.sum_win.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.sum_win, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.netto.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.netto, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.max_bet.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.max_bet, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.min_bet.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.min_bet, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.avg_bet.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Number")(item.avg_bet, {
                                  currency: item.currency,
                                })
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material px-5 py-3" },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: "secondary", title: "Rounds List" } },
                    [
                      _vm._usrFlagsSome({
                        key: `session.list`,
                        val: _vm.permissions.CREATE,
                      })
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                color: "primary",
                                "x-small": "",
                              },
                              on: { click: _vm.onExportOpen },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-down-bold")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "must-sort": "",
                          dense: _vm.dense,
                          "sort-by": "start_time",
                          "sort-desc": true,
                          headers: _vm.roundHeadersFiltered,
                          items: _vm.list,
                          "hide-default-footer": "",
                          options: _vm.options,
                          "server-items-length": _vm.options.itemsPerPage,
                          loading: _vm.loading,
                          "item-class": _vm.itemClass,
                          "footer-props": _vm.footerProps,
                          value: _vm.selected,
                          "item-key": "round_id",
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function ({ options, updateOptions }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c("InfinitePagination", {
                                        attrs: {
                                          footer: _vm.footerProps,
                                          dense: _vm.dense,
                                          options: options,
                                          isFirstPage: _vm.isFirst,
                                          isLastPage: _vm.isLast,
                                        },
                                        on: {
                                          "update:dense": function ($event) {
                                            _vm.dense = $event
                                          },
                                          "update:options": updateOptions,
                                          pagination: _vm.mxPaginationHandler,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "foot",
                              fn: function ({ options, updateOptions }) {
                                return [
                                  _c("tfoot", [
                                    _c("tr", [
                                      _c("td", { attrs: { colspan: "100%" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex justify-end" },
                                          [
                                            _c("InfinitePagination", {
                                              attrs: {
                                                footer: _vm.footerProps,
                                                dense: _vm.dense,
                                                options: options,
                                                isFirstPage: _vm.isFirst,
                                                isLastPage: _vm.isLast,
                                              },
                                              on: {
                                                "update:dense": function (
                                                  $event
                                                ) {
                                                  _vm.dense = $event
                                                },
                                                "update:options": updateOptions,
                                                pagination:
                                                  _vm.mxPaginationHandler,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.copy`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        text: "",
                                        "x-small": "",
                                      },
                                      on: {
                                        click: (e) => _vm.copyLine(e, item),
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "kajot-text" } },
                                        [_vm._v("mdi-content-copy")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.flags`,
                              fn: function ({ item }) {
                                return [
                                  _vm._l(item.flags, function (flag) {
                                    return [
                                      _vm.ROUND_FLAGS[flag]
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              key: flag,
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-avatar",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  size: "30",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              "v-avatar",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold kajot-anti-text--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .ROUND_FLAGS[
                                                                        flag
                                                                      ].title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.ROUND_FLAGS[flag]
                                                      .tooltip
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ]
                              },
                            },
                            {
                              key: `item.start_time`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("Date")(item.start_time)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.bet`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("Number")(item.bet, {
                                          currency: _vm.detail.currency,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.win`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("Number")(item.win, {
                                          currency: _vm.detail.currency,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.netto`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("Number")(item.netto, {
                                          currency: _vm.detail.currency,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.orientation`,
                              fn: function ({ item }) {
                                return [
                                  item.orientation === 1
                                    ? _c("v-icon", [
                                        _vm._v(" mdi-cellphone mdi-rotate-90 "),
                                      ])
                                    : _c("v-icon", [_vm._v("mdi-cellphone")]),
                                ]
                              },
                            },
                            {
                              key: `item.finished`,
                              fn: function ({ item }) {
                                return [
                                  item.finished
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "primary lighten-1" },
                                        },
                                        [_vm._v(" mdi-check ")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "error" } },
                                        [_vm._v(" mdi-close ")]
                                      ),
                                ]
                              },
                            },
                            {
                              key: `item.actions`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row justify-center",
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "primary darken-2",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              size: "22",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onRowClick(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-alpha-i-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Detail")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "primary darken-2",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              size: "22",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.linkOutside(
                                                                  "history",
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-alpha-d-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Round detail")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "primary darken-2",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              size: "22",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.linkOutside(
                                                                  "replay",
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-play-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Replay Round")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            "text-align": "center",
          },
          attrs: {
            app: "",
            color: "menu_background",
            transition: "slide-y-reverse-transition",
            timeout: _vm.timeout,
            "content-class": "kajot-text--text",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { fab: "", text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          _vm.copied = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "kajot-text", small: "" } },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.copied,
            callback: function ($$v) {
              _vm.copied = $$v
            },
            expression: "copied",
          },
        },
        [
          _c(
            "div",
            { staticClass: "align-center" },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information"),
              ]),
              _vm._v(" " + _vm._s(_vm.copiedText) + " "),
            ],
            1
          ),
        ]
      ),
      _c("FilterSideBar", {
        attrs: {
          filterButton: false,
          refreshButton: "",
          refreshText: "Refresh",
          locked: "",
          width: _vm.isMobile ? "" : "400px",
        },
        on: { refresh: _vm.reloadRounds },
      }),
      _c(
        "v-dialog",
        {
          staticClass: "my-2 fakin-dialog",
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c("RoundDetailModal", {
            key: _vm.selectedItem?.session_mongo_id,
            attrs: {
              item: _vm.selectedItem,
              device: _vm.detail.device,
              currency: _vm.detail.currency,
            },
            on: {
              openDetail: function ($event) {
                return _vm.linkOutside("history", _vm.selectedItem)
              },
              close: function ($event) {
                _vm.dialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "exportDialog",
            attrs: {
              builder: "paginatedObject",
              delimiter: ";",
              indeterminate: "",
              data: _vm.exportData,
              filename: `${_vm.detail.session_mongo_id}-rounds`,
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }